<template>
    <b-card title="Store Holidays">
        <b-form>
            <b-row>
                <b-col md="3">
                    <b-form-group label="Store Name">
                        <v-select v-model="storeName" label="storeName" placeholder="Select Store Name"
                            :options="storeNameOptions" />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Holiday Date">
                        <flat-pickr v-model="holidayDate" class="form-control" name="date"
                            placeholder="Please Enter Holiday Date" />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Description">
                        <b-form-input v-model="description" placeholder="Please Enter Description"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3" class="text-center">
                    <b-button block variant="primary" class="mt-2 mr-2" @click="addUpdateHolidays">{{
                        addUpdateButton
                    }}</b-button>
                </b-col>
            </b-row>
            <hr>
            <div>
                <!-- search input -->
                <div class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <label class="mr-1">Search</label>
                            <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                class="d-inline-block" />
                        </div>
                    </b-form-group>
                </div>

                <!-- table -->
                <vue-good-table :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                }" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                    <template slot="table-row" slot-scope="props">

                        <!-- Column: Action -->
                        <span v-if="props.column.field === 'action'">
                            <span>
                                <b-button
                                    @click="editButton(props.row.id, props.row.holidayDate, props.row.description)"
                                    variant="flat-primary"><feather-icon icon="EditIcon"
                                        class="text-body align-middle mr-25" /></b-button>
                                <b-button @click="deleteHolidays(props.row.id,props.row.storeName)" variant="flat-primary"><feather-icon
                                        icon="TrashIcon" class="text-body align-middle mr-25" /></b-button>
                            </span>
                        </span>

                        <!-- Column: Common -->
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>

                    <!-- pagination -->
                    <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap ">
                                    Showing 1 to
                                </span>
                                <b-form-select v-model="pageLength" :options="['10', '20', '50','100']" class="mx-1"
                                    @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                <span class="text-nowrap"> of {{ props.total }} entries </span>
                            </div>
                            <div>
                                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                    last-number align="right" prev-class="prev-item" next-class="next-item"
                                    class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </div>
                        </div>
                    </template>
                </vue-good-table>
            </div>
            <!-- <template>
             <div class="app-calendar overflow-hidden border">
                 <div class="row no-gutters">
                     
                     <div class="col position-relative">
                         <div class="card shadow-none border-0 mb-0 rounded-0">
                             <div class="card-body pb-0">
                                 <full-calendar ref="refCalendar" :options="calendarOptions" class="full-calendar" />
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </template> -->
        </b-form>
    </b-card>
</template>
  
<script>
import {
    BAvatar, BCard, BRow, BCol, BForm, BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/store/api'
import vSelect from 'vue-select'

// import FullCalendar from '@fullcalendar/vue'
// import useCalendar from '../apps/calendar/useCalendar.js'
import store from '@/store'
// import { onUnmounted } from '@vue/composition-api'
// import calendarStoreModule from '../apps/calendar/calendarStoreModule'
// import CalendarSidebar from '../apps/calendar/calendar-sidebar/CalendarSidebar.vue'
// import CalendarEventHandler from '../apps/calendar/calendar-event-handler/CalendarEventHandler.vue'


export default {
    components: {
        VueGoodTable, vSelect,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem, BCard, BRow, BCol, BForm, BButton, flatPickr

    },
    data() {
        return {
            addUpdateButton: 'Add',
            holidayDate: '',
            description: '',
            id: 0,
            pageLength: 10,
            dir: false,
            columns: [
            {
                    label: 'Store Name',
                    field: 'storeName',
                },
                {
                    label: 'Holiday Date',
                    field: 'holidayDate',
                },
                {
                    label: 'Holiday Description',
                    field: 'description',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            storeNameOptions: [],
            storeName: { 'id': this.$store.state.storeId, 'storeName': this.$store.state.storeName },

        }
    },
    methods: {
        getStoreName() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));

                    self.storeNameOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getHolidays() {
            this.id = 0;
            this.holidayDate = '';
            this.description = '';
            this.addUpdateButton = "Add";
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/StoreHolidays/', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.rows = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        addUpdateHolidays() {
            let self = this;
            if (!(self.holidayDate == '') && !(self.description == '')) {
                if (self.addUpdateButton == 'Add') {
                    var axios = require('axios');
                    var data = JSON.stringify({ "holidayDate": self.holidayDate, "description": self.description, "storeName": self.storeName.storeName });
                    axios(api.getApi('Post', '/masters/StoreHolidays/' + self.storeName.storeName, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Holiday Added Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            self.getHolidays();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    let self = this;
                    var axios = require('axios');
                    var data = JSON.stringify({ "holidayDate": self.holidayDate, "description": self.description });
                    axios(api.getApi('put', '/masters/StoreHolidays/' + self.id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getHolidays();
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Holiday Updated Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })

                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            } else {
                this.$swal({
                    title: '',
                    text: "Please Enter Holiday Date And Description.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }


        },

        deleteHolidays(id,storeName) {
            let self = this;
            self.$swal({
                title: '',
                text: "Are You Sure You Want To Delete!",
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    var axios = require('axios');
                    var data = '';
                    axios(api.getApi('delete', '/masters/StoreHolidaysDeleteById/' +id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getHolidays();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            })
        },

        editButton(id, holidayDate, description) {
            this.id = id;
            this.addUpdateButton = 'Update';
            this.holidayDate = holidayDate;
            this.description = description;

        }
    },
    // setup() {
    //     const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    //     // Register module
    //     if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    //     // UnRegister on leave
    //     onUnmounted(() => {
    //         if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    //     })

    //     const {
    //         refCalendar,
    //         isCalendarOverlaySidebarActive,
    //         event,
    //         clearEventData,
    //         addEvent,
    //         updateEvent,
    //         removeEvent,
    //         fetchEvents,
    //         refetchEvents,
    //         calendarOptions,

    //         // ----- UI ----- //
    //         isEventHandlerSidebarActive,
    //     } = useCalendar()

    //     fetchEvents()

    //     return {
    //         refCalendar,
    //         isCalendarOverlaySidebarActive,
    //         event,
    //         clearEventData,
    //         addEvent,
    //         updateEvent,
    //         removeEvent,
    //         refetchEvents,
    //         calendarOptions,

    //         // ----- UI ----- //
    //         isEventHandlerSidebarActive,
    //     }
    // },
    mounted() {

    },
    computed: {

    },
    created() {
        let self = this;
        self.getHolidays();
        self.getStoreName();
    },
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "@core/scss/vue/apps/calendar.scss";
</style>
